exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artigos-de-vidro-em-geral-js": () => import("./../../../src/pages/artigos-de-vidro-em-geral.js" /* webpackChunkName: "component---src-pages-artigos-de-vidro-em-geral-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-material-plastico-js": () => import("./../../../src/pages/material-plastico.js" /* webpackChunkName: "component---src-pages-material-plastico-js" */),
  "component---src-pages-orcamento-js": () => import("./../../../src/pages/orcamento.js" /* webpackChunkName: "component---src-pages-orcamento-js" */),
  "component---src-pages-porcelana-js": () => import("./../../../src/pages/porcelana.js" /* webpackChunkName: "component---src-pages-porcelana-js" */),
  "component---src-pages-produto-js": () => import("./../../../src/pages/produto.js" /* webpackChunkName: "component---src-pages-produto-js" */),
  "component---src-pages-sobre-js": () => import("./../../../src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */),
  "component---src-template-categoria-js": () => import("./../../../src/Template/categoria.js" /* webpackChunkName: "component---src-template-categoria-js" */),
  "component---src-template-categoria-plastico-js": () => import("./../../../src/Template/categoria-plastico.js" /* webpackChunkName: "component---src-template-categoria-plastico-js" */),
  "component---src-template-categoria-porcelana-js": () => import("./../../../src/Template/categoria-porcelana.js" /* webpackChunkName: "component---src-template-categoria-porcelana-js" */),
  "component---src-template-produto-js": () => import("./../../../src/Template/produto.js" /* webpackChunkName: "component---src-template-produto-js" */),
  "component---src-template-produto-plastico-js": () => import("./../../../src/Template/produto-plastico.js" /* webpackChunkName: "component---src-template-produto-plastico-js" */),
  "component---src-template-produto-porcelana-js": () => import("./../../../src/Template/produto-porcelana.js" /* webpackChunkName: "component---src-template-produto-porcelana-js" */)
}

