import React, { Fragment } from "react";
import PropTypes from "prop-types";

/* COMPONENTS */

/* MAIN SCSS */
import "../../scss/base.scss";

/* BOOTSTRAP*/
import "bootstrap/dist/css/bootstrap.min.css";

/*FONT*/
import "../../Font/Montserrat/Montserrat.css";
import "../../Font/Lato/Lato.css";
import "../../Font/Inter/Inter.css";
import Footer from "../Footer/footer";

const Layout = ({ children }) => {
  return (
    <Fragment>
      <main>{children}</main>
      <Footer />
    </Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
