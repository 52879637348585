import React from "react";
import "./footer.scss";
import Linkedin from "../../images/icons/linkedin-footer.svg";
import Youtube from "../../images/icons/youtube-footer.svg";

const Footer = () => {
  return (
    <>
      <footer id="footer-home-desktop">
        <div className="fundo-footer-verde">
          <div className="container">
            <div className="d-flex">
              <div className="d-block">
                <a href="/">
                  <p className="paginas-footer-fonte">
                    <b>Home</b>
                  </p>
                </a>
                <a href="/sobre">
                  <p className="paginas-footer-fonte">
                    <b>IVM Brasil</b>
                  </p>
                </a>
                <p className="paginas-footer-fonte-desk">
                  <b>Nossos Produtos</b>
                </p>
                <ul id="footer-list">
                  <a href="/artigos-de-vidro-em-geral">
                    {" "}
                    <li>Artigos de Vidros em Geral</li>
                  </a>
                  <a href="/porcelana">
                    {" "}
                    <li>Porcelana</li>
                  </a>
                  <a href="/material-plastico">
                    {" "}
                    <li>Material Plástico</li>
                  </a>
                </ul>
                <a href="/orcamento">
                  <p className="paginas-footer-fonte">
                    <b>Orçamento</b>
                  </p>
                </a>
                <a href="/contato">
                  <p className="paginas-footer-fonte">
                    <b>Contato</b>
                  </p>
                </a>
              </div>
              <div className="d-block" style={{ marginLeft: "150px" }}>
                <p className="paginas-footer-fonte">
                  <b>Termos de Busca</b>
                </p>
                <p className="paginas-footer-fonte-desk">
                  vidros de laboratorio
                </p>
                <p className="paginas-footer-fonte-desk">
                  vidraria para laboratorio
                </p>
                <p className="paginas-footer-fonte-desk">
                  equipamento de laboratorio
                </p>
                <p className="paginas-footer-fonte-desk">
                  vidraria de precisão
                </p>
                <p className="paginas-footer-fonte-desk">
                  vidrarias volumétricas
                </p>
              </div>
              <div className="d-block" style={{ marginLeft: "250px" }}>
                <p className="siga-redes-sociais-footer">
                  Siga-nos nas Redes Sociais
                </p>
                <div className="d-flex mt-3">
                  <img src={Linkedin} alt="Linkedin"></img>
                  <img
                    src={Youtube}
                    style={{ marginLeft: "30px" }}
                    alt="Youtube"
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="d-flex justify-content-between mt-3 mb-3">
              <p className="direitos-reservados">
                © IVM Brasil Todos direitos reservados
              </p>
              <p className="direitos-reservados">
                Desenvolvido por:{" "}
                <a
                  href="https://www.clouddog.com.br/"
                  style={{ textDecoration: "none", color: "black" }}
                  title="Ir para o site da CloudDog"
                >
                  CloudDog
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>

      <footer id="footer-home-mobile">
        <div className="fundo-footer-verde-mobile">
          <div className="container">
            <div className="d-block">
              <a href="/">
                <p className="paginas-footer-fonte">
                  <b>Home</b>
                </p>
              </a>
              <a href="/sobre">
                <p className="paginas-footer-fonte">
                  <b>IVM Brasil</b>
                </p>
              </a>
              <p className="paginas-footer-fonte">
                <b>Nossos Produtos</b>
              </p>
              <ul id="footer-list">
                <a href="/artigos-de-vidro-em-geral">
                  <li>Artigos de Vidros em Geral</li>
                </a>
                <a href="/porcelana">
                  <li>Porcelana</li>
                </a>
                <a href="/material-plastico">
                  <li>Material Plástico</li>
                </a>
              </ul>
              <a href="/orcamento">
                <p className="paginas-footer-fonte">
                  <b>Orçamento</b>
                </p>
              </a>
              <a href="/contato">
                <p className="paginas-footer-fonte">
                  <b>Contato</b>
                </p>
              </a>
            </div>
            <div className="d-block" style={{ marginTop: "50px" }}>
              <p className="paginas-footer-fonte">
                <b>Termos de Busca</b>
              </p>
              <p className="paginas-footer-fonte">vidros de laboratorio</p>
              <p className="paginas-footer-fonte">vidraria para laboratorio</p>
              <p className="paginas-footer-fonte">equipamento de laboratorio</p>
              <p className="paginas-footer-fonte">vidraria de precisão</p>
              <p className="paginas-footer-fonte">vidrarias volumétricas</p>
            </div>
          </div>
        </div>
        <div className="fundo-footer-amarelo-mobile">
          <div className="container">
            <div className="row">
              <p className="siga-redes-sociais-footer">
                Siga-nos nas Redes Sociais
              </p>
              <div className="d-flex mt-3">
                <img src={Linkedin} alt="Linkedin"></img>
                <img
                  src={Youtube}
                  style={{ marginLeft: "30px" }}
                  alt="Youtube"
                ></img>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row mt-2 mb-2">
            <p className="desenvolvido-por-mobile text-center">
              Desenvolvido por:{" "}
              <a
                href="https://www.clouddog.com.br/"
                style={{ textDecoration: "none", color: "black" }}
                title="Ir para o site da CloudDog"
              >
                CloudDog
              </a>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
