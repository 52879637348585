module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/logo-png.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"375a82d1c288a942987c639aba4d1888"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/codebuild/output/src2948362998/src/src/components/Layout/index.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-cloudofertas/gatsby-browser.js'),
      options: {"plugins":[],"env":{"dev":{"siteId":"01ef9b05-b7ea-403c-b993-bce5e49e1c36","accessToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVlODkwYzAzLTFlOGItNGFhMy1hN2MyLWU4NTRiNTlkODE2MiIsInVzZXIiOiJhYTBiYWE3MS0yM2UzLTQ2YTctYjY5ZC1kNDk4MzAzNmI4OGUiLCJzaXRlIjoiMDFlZjliMDUtYjdlYS00MDNjLWI5OTMtYmNlNWU0OWUxYzM2IiwiaWF0IjoxNjkwOTEzOTM1fQ.wIIXZA34pY9NaXCz1vvBEBiFHSkQt3Q5iEjT0jZQK0Y"},"prd":{"siteId":"01ef9b05-b7ea-403c-b993-bce5e49e1c36","accessToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVlODkwYzAzLTFlOGItNGFhMy1hN2MyLWU4NTRiNTlkODE2MiIsInVzZXIiOiJhYTBiYWE3MS0yM2UzLTQ2YTctYjY5ZC1kNDk4MzAzNmI4OGUiLCJzaXRlIjoiMDFlZjliMDUtYjdlYS00MDNjLWI5OTMtYmNlNWU0OWUxYzM2IiwiaWF0IjoxNjkwOTEzOTM1fQ.wIIXZA34pY9NaXCz1vvBEBiFHSkQt3Q5iEjT0jZQK0Y"}}},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
